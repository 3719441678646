
@import "../variabls";
$main-color: $primary;
//Alternative colors
$main-green: #ACF815;
$main-blue: #2CD7FE;
$main-gray: #2d2d2d;
//$main-gray: #313D52;
$main-yellow: #F8FF1E;
$secondary-color: complement($main-color);
$bigpicSize: 300px;
$checkboxSize: 20px;
$lightGray: #737373;
