@import "animaciones";
@import "variables";

@mixin shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);
}

@mixin opened {
  display: block;
  animation: slideDown;
  animation-duration: .7s;
  animation-timing-function: ease;
  overflow: hidden;
}

@mixin fontAwesome {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .none, .fa.none {
  display: none;
}

.none.opened {
  display: block;
}

body {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  background: #eee;
}

html,
body {
  height: 100%;
}

.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -60px;
  padding: 0 0 60px;

  & > .container {
    padding: 20px 15px 20px;
  }
}

.jumbotron {
  text-align: center;
  background-color: transparent;

  .btn {
    font-size: 21px;
    padding: 14px 24px;
  }
}


.not-set {
  color: #c55;
  font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: "\f15d";
}

a.desc:after {
  content: "\f881";
}

.sort-numerical {


  a.asc:after {
    content: "\f162";
  }

  a.desc:after {
    content: "\f886";
  }

}

.sort-ordinal {
  a.asc:after {
    content: "\f160";
  }

  a.desc:after {
    content: "\f884";
  }
}

.grid-view {
  td {
    white-space: nowrap;
  }

  .filters input,
  .filters select {
    min-width: 50px;
  }
}

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}

.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
  padding: 15px;
  border: none;
  @media(max-width: 767px) {

    display: block;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }


  :focus {
    outline: none;
  }

}

.order-resume-discount {
  display: block;
  color: red;
  font-size: 10px;
}


.navbar-toggle {
  background-color: $main-color;

  .icon-bar {
    background-color: white;
  }
}

.site-error {
  text-align: center;

  .statusCode {
    color: $main-color;
    font-size: 160px;
    font-weight: bold;
  }

  .contact {
    a {
      color: $main-color;
      text-decoration: underline;
    }
  }
}

h1, h2, h3, h4 {
  font-weight: 300;
}

input[type="text"], input[type="password"] {
  border: none;
  border-bottom: 1px solid #a3a3a3;
  border-radius: 0;
  box-shadow: none !important;

}

.breadcrumb-wrapper:not(:empty) {
  min-height: 33px;
  text-align: left;
  border-top: 1px solid #ddd;

  .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
  }
}


.modal-body {
  @for $i from 1 through 6 {
    h#{$i} {
      border-bottom: 1px dashed #a1a1a1;
      padding-bottom: 10px;
      margin-top: 1.3em;

      i {
        color: $main-color;

      }
    }
  }
}

.main-content {
  position: relative;
}

/* button group */
@mixin btn-group {
  .btn {
    display: inline-block;
    font-size: inherit;
    padding: 5px 12px;

    &-default {
      border-color: #ddd;
    }

    border-radius: 30px;
  }
  vertical-align: top;
  .dropdown-menu {
    padding: 20px;
    border-radius: 3px;
    border: none;

    li {
      padding: 5px 0;
    }

    a {
      padding: 5px 20px;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 30px;

      &:hover {
        border-color: #ddd;
      }
    }
  }
  .btn-group {

    .btn {
      font-size: inherit;
      padding: 5px;

      &-default {
        border-color: #ddd;
      }

      &:first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        padding-left: 15px;
        padding-right: 10px;
      }

      &:last-child:not(.dropdown-menu) {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }

      + .dropdown-toggle {
        padding-right: 10px;
      }
    }

    .dropdown-toggle {
      &:not(:first-child) {

        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }
}

#configurator {

  > .need-file {
    background-color: $main-color;
    text-align: center;
    color: white;
    padding: 5px 20px 5px 10px;

  }

  label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.vert-cent {
      position: relative;

      input[type="checkbox"], input[type="radio"] {
        position: absolute;
        top: 45%;
        transform: translateY(-45%);

      }
    }

  }

  input:disabled, option:disabled {
    &:disabled {
      display: none;

      + label {
        display: none;
      }
    }
  }

}

.simpleRow {
  margin-bottom: 10px;

  label {
    line-height: 34px;
  }
}

.navbar {
  a {
    color: #333;
  }
}

header {
  background-color: white;

  .right-actions {
    margin-top: 10px;

  }

  @include btn-group;

  .dropdown-toggle {
    margin-top: 0px !important;
    align-items: center;

    &:after {
      font-family: "Font Awesome 5 Pro";
      content: "\f107";
      border: none;
      line-height: 1;
      vertical-align: middle;
      color: $body-color;
      font-size: 0.75rem;
    }
  }

  .dropdown-menu {

    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0px;
    border-radius: 0;

    li {
      padding: 0px;
    }

    a {
      padding: 5px 10px;
      text-align: left;
      border: none;
      border-radius: 0px;
      width: 100%;

      &:hover {
        color: $primary;
      }
    }
  }
}

.logo {

  display: inline-block;
  padding: 5px 20px;
  text-transform: lowercase;
  font-weight: 700;
  letter-spacing: -2px;
  font-size: 28px;
  color: $main-color;
  margin-top: 10px;
  margin-bottom: 15px;

  &:hover {
    animation: shake;
    color: $main-color;
    animation-iteration-count: 1;
    animation-duration: .2s;
    text-decoration: none;
  }

  span {
    &:first-child {

    }

    &:last-child {
      color: #2d2d2d;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 426px) {
    float: left;
  }

}

@media screen and (min-width: 768px) {
  .main-bigmenu {
    > li > a {
      padding: 18px 10px !important;
      font-weight: 500 !important;

      &:hover {
        background-color: $main-color !important;
      }
    }
  }
}

.bigmenu .topCartButton {
  a {
    background-color: $main-color;
    color: white;
  }
}


.sac-top, .login-top {
  color: $main-gray;
  display: inline-block;
  padding: 5px 10px;
  vertical-align: middle;

  i {
    margin-right: 10px;
  }
}

.login-top {
  border: 1px solid #ddd;
  border-radius: 30px;
}

nav .topCartButton {
  border: 1px solid $main-color;
  border-radius: 6px !important;
  overflow: hidden;
  background-color: $main-color;

  a {
    color: white;
  }
}

.btn {
  &.block {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 4px;
  }
}

.btn-xenon {
  background-color: $main-color;
  color: white;
  border-bottom: 4px solid darken($main-color, 20);

  &.btn-xs {
    border-bottom-width: 1px;
  }

  i {
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid white;
  }

  &:hover {
    color: lighten($main-color, 40);
    transform-origin: bottom;
    background-color: lighten($main-color, 5);
  }

  &.disabled {

    background-color: #b7c0ca;
    border-bottom-color: #333;

    i {
      border-right-color: #999;
    }

  }
}

.btn-xenon-alt {
  @extend .btn-xenon;
  border-bottom-color: darken($secondary-color, 10);
  background-color: $secondary-color;
  color: darken($secondary-color, 30);

  &:hover {
    background-color: lighten($secondary-color, 5);
    color: darken($secondary-color, 20);
  }

  i {
    border-right-color: darken($secondary-color, 20);
  }
}

//Xenon Columns
.xenon-row {
  display: flex;
  @media screen and (max-width: 991px) {
    flex-flow: column;
    [class*="xenon-"] {
      width: 100%;
    }
  }
}

.xenon-col {
  align-self: center;
}

@for $i from 1 through 12 {
  .xenon-#{$i} {
    width: ((100% / 12) * $i);
    @extend .xenon-col;
  }
}

.panel-xenon {
  @include shadow;

  .panel-heading {
    border-bottom: 1px solid #eee;
  }

  .panel-body {
    //    border: 1px solid #eee;

    .description {
      color: #6d6d6d;
    }
  }

  .panel-footer {
    #configurator & {
      min-height: 40px;

    }

    .prices {
      min-height: 42px;
      font-weight: bold;
      color: $main-color;
    }
  }

  .info-icon {
    color: #56a3ff;

    &:hover {
      cursor: pointer;
    }

    &.opened {
      color: #ddd;
    }
  }

  .value-description {
    background-color: #eee;
    padding: 10px;

    .preview a {
      width: 40px;
      height: 40px;
    }

    color: #5d5c5c;

    .title {
      font-size: 12px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }

    &.opened {
      @include opened;
    }

  }

  .feature-locked {
    padding: 6px 12px !important;
    width: 100%;
  }


  .upload-file {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 10px;
    clear: both;

    i {
      font-size: 6rem;
      border: 1px solid $lightGray;
      margin: auto;
      margin-bottom: 5px;
      padding: 15px;
      width: 150px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .clean-file {
    float: right;
    border: none;
    background: transparent;
  }

  &.collapsed {

    .need-file {
      background-color: $main-color;
      text-align: center;
      color: white;
      display: inline-block;
      padding: 5px 20px 5px 10px;
      border-bottom-right-radius: 10px;

    }

    .feature-image {
      width: 50px;
      display: block;
      margin: auto;
      height: auto;
      border-radius: 100%;
    }

    .feature-info {
      padding: 15px;

      &.opened {
        @include opened;
      }
    }

    .panel-body {
      border-bottom: 1px solid #f0f0f0 !important;

      &:not(.view6) {
        padding: 30px 40px;
      }

      &:last-child {
        border-bottom: none !important;
      }

      > .row {
        + .row {
          margin-top: 10px;
        }
      }
    }

    label {
      &.feature {
        color: #5d5c5c;
      }
    }

    .form-control {
      line-height: 1em;
      padding: 0 12px;
      height: 28px;
      box-shadow: none;
    }
  }
}

.floatPanel {
  background: white;

  .h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    color: #eee;
  }
}

/* Inquiry Panel */
.inquiry {

  &.loading {
    div {
      opacity: 0.8;
    }

    .loader {
      display: block !important;
    }
  }

  .resume {
    position: relative;

    .loader {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 50px;
    }
  }

  .productName {
    font-size: 14px;
    font-weight: bold;
  }

  .listCaract {
    .valueResume {
      display: block;
      font-size: 10px;
      padding: 2px 3px;
      color: #666;

    }

    span {
      font-weight: bold;
    }
  }

  .totalResume {
    #cTaxQty {
      width: initial;
      margin-left: 0;
    }

    text-align: right;
  }
}

/*product Banner*/

.productIntro {
  padding: 0 15px;
  background: white;

  .description {
    text-align: justify;
    padding: 10px 10px 20px 10px;

  }

  .bx-wrapper .bx-controls-direction a {
    z-index: 1040 !important;
  }
}

/* CSS for the options groups and container*/

.radioOptionsGroup, .checkboxOptionGroup {
  div {
    label {
      display: inline-block;
      padding: 5px 0px 5px 10px;
    }

  }

  div:last-child label {
    &:last-child {
      border-bottom: none;
    }
  }
}

.checkboxOptionGroup {
  padding-left: 10px;
}

// Plazos selector

.product-time-group {
  ul {
    text-align: center;
    list-style: none;
    padding: 0;

    li {
      display: inline-block;

      label {
        text-align: center;
        width: 90px;
        display: block;
        overflow: hidden;
        height: 109px;
        padding: 10px 0 0;
        margin: 10px;
        border: 1px solid $main-gray;
        color: $main-gray;
        position: relative;

        &:hover {
          cursor: pointer;
          background-color: #eee;
        }

      }

      input {
        display: none;

        &:checked {
          + label {
            //            background-color: $main-color;
            //            color:white;
            color: $main-color;

            &:after {
              @include fontAwesome;
              content: "\f00c";
              position: absolute;
              color: white;
              top: 0;
              right: 0;
              background-color: $main-color;
              padding: 4px;
            }
          }
        }
      }
    }
  }

  .hint {
    text-align: right;
    font-size: 10px;
    color: #979797;
  }

  .weekday {
    font-size: 10px;
    display: block;
    text-transform: capitalize;
  }

  .day {
    font-size: 40px;
    line-height: 40px;
    display: block;
    font-weight: 100;
  }

  .month {
    display: block;
  }

  .name {
    background-color: $main-color;
    display: block;
    width: 100%;
    margin-top: 5px;
    color: white;
    font-weight: normal;
    padding: 2px;
  }
}

//Images selector

.image-wrapper, .colors-wrapper {
  .bx-viewport {
    //    width: 90% !important;
    margin: auto;
  }

  .bx-controls-direction {
    .bx-next {
      height: 100px;
      width: 20px;
      text-indent: 0 !important;
      background: transparent;
      transform: translateY(-50%);
      right: 10px;
      font-size: 34px;
      text-align: right;

      &:after {
        position: absolute;
        padding: 10px;
        z-index: 999;
        @include fontAwesome;
        color: #333;
        top: 50%;
        transform: translateY(-50%);
        content: "\f105";
      }
    }

    .bx-prev {
      @extend .bx-next;
      text-align: left;
      right: auto;

      &:after {
        content: "\f104";
        left: -25px;

      }
    }
  }

  padding: 0;
  margin: 0;
  list-style: none;

  > div {
    text-align: center;
  }

  .image {
    width: 100%;
    height: 80px;
    min-width: 78px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  label {
    padding: 0;
    margin: 0;
    border: 1px solid transparent;

    &:hover {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.17)
    }
  }

  input {
    display: none;

    &:disabled {
      + label {
        display: none;
      }
    }

    &:checked {
      + label {
        border: 1px solid $main-color;
        background-color: $main-color;
        color: white;
        position: relative;

        &:after {
          @include fontAwesome;
          content: "\f00c";
          position: absolute;
          color: white;
          top: 0;
          right: 0;
          background-color: $main-color;
          padding: 4px;
        }
      }
    }
  }
}

.color-filter {
  margin-bottom: 20px;

  input {
    border: 1px solid #ddd;
    border-radius: 30px;
    text-align: center;
  }

  .disclaimer {
    font-size: 10px;
    color: #c9c9c9;
  }
}

.pant-suggestion {
  padding: 6px;
  border-bottom: 1px solid #eee;

  &:hover {
    background: #eee;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

}

.colors-wrapper {

  .show-all {
    margin: 10px;

    &.bx-disabled {
      span {
        &:last-child {
          display: block;
        }

        &:first-child {
          display: none;
        }
      }
    }
  }

  /*Normal mode*/

  > .color-selector {
    .color-wrapper {
      max-width: 90px;
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 15px;
      //      min-height: 130px;
      vertical-align: top;
    }
  }

  /* Bx mode*/
  input {
    &:checked {
      + label {
        background: white;
        color: $main-color;

      }
    }

  }

  .pant {
    font-size: 10px;
    display: block;
  }

  .color-sample {
    display: block;
    height: 70px;
    width: 70px;
    margin: 5px;
    border-radius: 100%;
    box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.55);
    border: 1px solid #eee;

    &.image-sample {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

//End color/image Selector

/*Bootstrap*/
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  background-color: $main-color;
}

@media screen and (min-width: 768px) {

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nav > li > a:hover, .nav > li > a:focus {
  color: #333;
}

.panel {
  border-radius: 0
}

.microGallery {
  padding: 0;
  text-align: center;

  .preview {
    display: inline-block;
    padding: 5px;
    background: white;
    border: 1px solid #eee;
    margin: 3px;

    a {
      display: block;
      height: 60px;
      width: 60px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

    }
  }
}

.option-value {
  vertical-align: middle;
}

.option-pic {
  padding: 8px;
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  position: relative;

  &:hover {
    .bigpic {
      z-index: 999;
      display: block;
    }
  }

  .bigpic {
    display: none;
    position: absolute;
    left: 150%;
    top: 50%;
    transform: translateY(-50%);
    height: $bigpicSize;
    width: $bigpicSize;
    padding: 10px;
    border: 1px solid #5e5e5e;
    background-position: center;
    background-size: contain;
    background-color: white;
    background-origin: content-box;
    background-repeat: no-repeat;
    box-shadow: -1px 2px 11px 0px rgba(0, 0, 0, 0.29);

  }
}

//Bxslider Fix

.no-padding {
  padding: 0;
}

.bx-viewport {
  ul {
    padding: 0;
    list-style: none;
  }

  .bx-controls-direction {
    img {
      visibility: hidden;
    }
  }

}

.bx-wrapper, .bx-viewport {
  margin-bottom: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none !important;
}

.small-cart {
  i {
    color: #999;
    margin-right: 10px;
  }
}

.well.white {
  background-color: white;
  @include shadow;
  @for $i from 1 through 6 {

    h#{$i} {
      margin: 0;

      i {
        color: $main-color;

      }
    }
  }
}

.main-cart {
  .h3 {
    font-size: 16px;
    color: #ddd;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .well {
    background-color: white;
  }

  .productName {
    text-transform: uppercase;
    color: $main-color;
    padding: 10px;
    font-weight: bold;
    white-space: initial;
    @media(max-width: 768px) {
      font-size: 1rem;
    }
  }

  .productDescription {
    border-top: 1px solid #ddd;
    margin-top: 10px;
    padding-top: 10px;

    @media(max-width: 768px) {
      border-top: 1px solid #ddd;
      margin-top: 0px;
      padding-top: 0px;
    }

    & + .text-danger {
      white-space: normal;
    }
  }

  .form-group {
    label {
      @media(max-width: 768px) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  .productData {
    float: left;
    width: 60%;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .productImage {
    float: left;
    width: 40%;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .attributes {
    list-style: none;
    padding-left: 20px;

    li {
      white-space: normal;
    }
  }

  .finalResume {
    text-align: right;

    div {
      padding: 3px;
    }

    span {
      width: 100%;
      display: inline-block;

      &.vat_incl {
        border-top: 1px solid #ddd;
        font-weight: bold;
      }
    }

    .free {
      color: white;
      font-size: 0.9rem;
      padding: 3px 5px;
      background: $main-color;
    }

    .shipping {
      .qty {
        position: relative;

        &.free-shipping:after {
          position: absolute;
          content: "";
          border-top: 1px solid $main-color;
          top: 48%;
          transform: rotateZ(-20deg);
          width: 30px;
          left: 10px;
        }
      }
    }

    div {
      padding: 3px;
    }

    div > span {
      margin-left: 10px;
      width: 90px;
      display: inline-block;
    }

    .vat_excl {

    }

    .vat_incl {
      span {
        border-top: 1px solid #eee;
        font-weight: bold;
      }
    }

  }

  .expander {
    &:hover {
      cursor: pointer;
    }

    &.open {
      i {
        &:before {
          content: "\f00d"
        }
      }
    }
  }

}

#isCompany {

  &:checked {
    & ~ .field-address-company {
      display: block;
    }
  }
}

.field-address-company {
  -webkit-animation: isCompany;
  animation-duration: 1s;
  animation-iteration-count: 1;
  display: none;
}

tbody > tr:nth-of-type(odd) .productDescription {
  border-top-color: #eee;
}

.payment-options {
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    vertical-align: top;
    width: 33.3333333%;
    text-align: center;

    &:last-child {
      label {
        border-right: 1px solid #eee;

      }
    }

    label {
      transition: transform 0.3s ease;
      height: 75px;
      width: 100%;
      padding: 10px;
      border: 1px solid #eee;
      border-right: none;
      line-height: 20px;

      @media(max-width: 768px) {
        font-size: 0.75rem;
        padding: 10px 5px;
      }

      &:hover {
        cursor: pointer;
      }

    }

    i {
      display: block;
      font-size: 20px;
      margin: auto;
    }
  }

  input {
    display: none;

    &:not(:checked) {
      + label {
        //        transform:scale(0.9);

      }
    }

    &:checked + label {
      //      transform: scale(1.05);

      background: $main-color;
      color: white;
    }
  }
}

#selectoraddress {
  .selector {
    display: none;
  }

  .changeaddress {
    display: block;

  }

  &.open {
    .changeaddress {
      display: none;
    }

    .selector {
      display: block;
    }
  }
}

.address-main {
  ul {
    list-style: none;
    padding: 0;
  }
}

.legal {
  max-height: 160px;
  overflow-y: auto;
  color: #676767;
  @for $i from 1 through 6 {
    h#{$i} {
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px dashed #a1a1a1;
      padding-bottom: 10px;
      margin-top: 1.3em;

      i {
        color: $main-color;

      }
    }
  }

}

.order-status {
  padding: 5px 8px;
  border-radius: 3px;
}

.order-view, .pdf-item {
  .finalResume {
    text-align: right;

    div {
      padding: 3px;
    }

    span {
      margin-left: 10px;
      width: 90px;
      display: inline-block;

      &.vat_incl {
        border-top: 1px solid #bdbdbd;
        font-weight: bold;
      }
    }
  }

  .progress {
    display: none;
  }

  .productName {
    text-transform: uppercase;
    color: $main-color;
    padding: 10px;
    font-weight: bold;

  }

  .fileinput-button {
    .replace {
      display: none;
    }

    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }

  .filesUploaded {
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
    margin-bottom: 5px;

    .uploaded-date {
      color: #a2a2a2;
    }

    .timeLeft {
      white-space: normal;
      display: block;
    }

    .file {
      font-weight: bold;
      padding: 4px 10px;
      display: inline-block;
      border: 1px solid;
      border-radius: 30px;

      i {
        margin-right: 10px;
      }
    }

    .fileDate {
    }
  }

  .newFile {
    .fileinput-button {
      background-color: $main-color;
      border-color: darken($main-color, 10);
      color: white;
    }
  }

  .filesOk {
    .fileinput-button {
      background-color: slategray;
      border-color: darken(slategray, 10);
      color: white;
    }

    .glyphicon:before {
      content: "\e031";
    }

    .replace {
      display: inline-block;
    }

    .select {
      display: none;
    }
  }

  $errorColor: #ff6516;

  .filesKo {
    .fileinput-button {
      background: $errorColor;
      border-color: darken($errorColor, 15);

      .glyphicon:before {
        content: "\e031";
      }
    }

    .replace {
      display: inline-block;
    }

    .select {
      display: none;
    }
  }

  .orderState {
    margin-bottom: 0;
  }
}

.nota {
  color: #b4b4b4;
}

.site-index {
  .bx-wrapper {
    background: transparent;

    .well {
      min-height: 140px;
    }
  }
}

.site-category-index, .items_list {
  ul {
    padding: 0;
  }

  li {
    display: inline-block;
    width: 49%;
    padding: 15px 0;
    @media screen and (min-width: 768px) {
      &:nth-child(2n+1) {
        padding-right: 30px;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;
      padding: 10px;
      .price {
        font-size: 18px !important;
      }
    }
  }
}

.categoryList {
  position: relative;
  background: $main-gray;
  @include shadow;

  h3 {
    margin-top: 0;
    color: lighten($main-color, 5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 26px;
  }

  .categoryImage {
    display: inline-block;
    vertical-align: top;
    width: 49%;

    a {
      display: block;
      height: 200px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .desde {
    color: $main-color;
    text-align: right;

    .price {
      line-height: 1;
      display: block;
      font-size: 40px;
    }
  }

  .categoryDescription {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    padding: 10px;
    color: white;
    font-size: 12px;

    a {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: block;
      font-size: 14px;
      border: 1px solid $main-color;
      background-color: $main-gray;
      padding: 5px 15px;
      color: white;
      text-transform: uppercase;
      box-shadow: 0px 0px 0px 3px $main-gray;

    }
  }
}

.filesStatus {
  .infoLink {
    display: block;

    &:hover {
      cursor: pointer;
    }
  }

  .alert {
    padding: 3px;
    font-size: 12px;
    white-space: normal;
  }

  ul {
    padding-left: 20px;
    font-weight: bold;
  }
}

.addedgif {
  height: 100px;
  width: 100px;
  margin: auto;
  display: block;
  position: relative;

  span, i {
    opacity: 0;
    position: absolute;
    top: 5px;
    left: 5px;
    height: 80px;
    width: 80px;
    display: block;

  }

  i {
    top: 0;
    color: white;
    font-size: 40px;
    line-height: 80px;
    animation: slideIn;
    animation-iteration-count: 1;
    animation-duration: .4s;
    animation-timing-function: ease;
    animation-delay: .4s;
    animation-fill-mode: forwards;

  }

  span {
    border-radius: 100%;
    background-color: $main-color;
    overflow: hidden;
    transform-origin: center center;
    animation: growUp;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: .1s;

    &:first-child {
      animation-duration: .4s;
      animation-delay: .2s;
      top: 0;
      left: 0;
      height: 90px;
      width: 90px;
      background: white;
      border: 1px solid $main-color;

    }
  }

}

.bigmenu-page-panel {
  width: 100%;
  background-color: $main-gray !important;

  .product-index > *:not(ul) {
    display: none;
  }
}

.html-banner {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 3px;
  padding: 10px;
  color: white;
  background-color: #2d2d2d;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.17);

  margin-bottom: 20px;

  i {
    color: $main-color;
    font-size: 30px;
    display: block;
    margin: 0;
    padding: 0;
  }

  small {
    line-height: .85%;
  }

  &.featured {
    background-color: $main-color;
  }

  &.green {
    background-color: $main-green;

    .content {
      color: $main-gray;

      i {
        color: $main-gray
      }
    }
  }

  &.blue {
    background-color: $main-blue;

    .content {
      color: $main-gray;

      i {
        color: $main-color
      }
    }
  }

  &.white {
    background-color: white;

    .content {
      border-color: #ddd;
      color: $main-gray;

      i {
        color: $main-color
      }
    }
  }

  p {
    margin: 0;
  }

  strong {
    display: block;
    letter-spacing: initial;
  }

  .content {
    width: 100%;
    padding: 30px 10px;
    border: 2px dashed rgba(white, .7);
  }

}

.sac {
  list-style: none;
  padding: 0;

  li {
    border: none;
  }

  .title {
    font-size: 18px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;

    margin-bottom: 10px;
  }

  .image {
    margin: 10px 0;
    background-size: cover;
    height: 75px;
    background-position: center;

    &.faq {
      background-image: url('../../images/custom_service/faq.jpg');
    }

    &.technical {
      background-image: url('../../images/custom_service/tecnical.jpg');
    }

    &.tracking {
      background-image: url('../../images/custom_service/tracking.jpg');
    }

    &.complaints {
      background-image: url('../../images/custom_service/recl.jpg');
    }

  }

  .content {
    height: 100px;
  }
}

/* Claims */
#reason-information {
  padding: 10px;
  border: 3px solid #ddd;
  background-color: #eee;
  margin-bottom: 15px;
}

.site-faq {
  .index {
    h4 {
      text-align: center;
      padding-bottom: 15px;
    }

    ul {
      padding-left: 18px;
      list-style-type: square;
    }

    li {
      margin-bottom: 10px;
    }
  }

  .faqs {
    background-color: white;
    padding: 30px;
    padding-top: 1px;
    @include shadow;

    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  dl {

  }

  dt {
    color: $main-gray;

    &:before {
      @include fontAwesome;
      content: "\f128";
      font-size: 40px;
      vertical-align: middle;
      color: #eee;
      background-color: #ddd;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-right: 10px;
    }
  }

  dd {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
  }
}

$footer-text-color: #949494;

footer {

  background-color: $main-gray;
  color: $footer-text-color;
  padding: 60px 0 0;

  .bottom {
    margin-top: 60px;
    text-align: center;
    padding: 20px;
    background-color: darken($main-gray, 5);

    p {
      margin: 0;
    }
  }

  h5 {
    padding-right: 30px;
    font-weight: 700;
    color: lighten($footer-text-color, 10);
    text-transform: uppercase;
    border-bottom: 1px solid darken($main-gray, 3);
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;

    &:after {
      position: absolute;
      bottom: -1px;
      left: 0;
      content: "";
      display: block;
      width: 30%;
      min-width: 95px;
      height: 1px;
      background-color: $main-color;
    }
  }

  .shop_name {
    color: white;
  }

  a {
    font-size: 11px;
    color: inherit;

    &:hover {
      color: white;
    }
  }

  ul, ol {
    padding: 0;

    li {
      padding: 5px 8px;
      list-style: none;
    }
  }
}

.cms-view {
  img {
    float: none;
    margin-right: auto;
  }

  .main-content {
    p {
      text-align: justify;
    }

    img {
      max-width: 100%;
      float: left;
      margin-right: 10px;
      height: auto;
    }
  }
}

.site-colores, .site-nearpantone, .site-nearral {
  .color-group-title {
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  .color-list {
    list-style: none;
    padding: 0;
  }

  .color-wrapper {
    padding: 20px;

    &:hover {
      .overlay {
        opacity: 0;
      }
    }
  }

  .color-name {
    color: #6f6f6f;
  }

  .color {
    @include shadow;
    //     MAtt overlay
    //        background: linear-gradient(156deg, rgba(255,255,255,0) 30%,rgba(255,255,255,.41) 140%);
    // Shine overlay
    //    linear-gradient(135deg, rgba(255, 255, 255, 0) 65%, rgba(255, 255, 255, 0.08) 0, rgba(255, 255, 255, 0) 125%)
    background-color: white;

    .sample {
      position: relative;
      display: block;
      height: 100px;

      .overlay {
        transition: opacity .4s ease;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        //        background: linear-gradient(106deg, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.11) 46%,rgba(255, 255, 255, 0) 76%);
      }
    }

    &[title*='Gloss'] .overlay {
      background: linear-gradient(135deg, rgba(255, 255, 255, 0) 65%, rgba(255, 255, 255, 0.08) 0, rgba(255, 255, 255, 0) 125%);

    }

    &[title*='Matt'] .overlay {
      background: linear-gradient(156deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, .41) 140%);
    }
  }
}

.category-wrap-ajax {
  .panel-background {
    box-shadow: none;
    height: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .menu-item-content {
      text-align: right;

      h2 {
        font-size: 20px;
      }
    }
  }
}

.table.table-responsive {
  display: table;

  thead {
    width: 100%;
  }

  tbody {
    width: 100%;
  }
}

@media(max-width: 768px) {

  .table.table-responsive {
    thead {
      display: none;
    }

    tbody {
      tr {
        border: 1px solid $lightGray;
      }

      td {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 25% !important;
        border: none !important;

        div {
          width: 100%;
          min-width: 100%;
          flex: 1;
        }
      }

      td:empty {
        display: none;
      }

      td:before {
        padding-top: 0.75rem;
        display: block;
        content: attr(data-th);
        font-weight: bold;
        text-align: left;
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 0.75rem;
        width: calc(25% - 10px);
        padding-right: 10px;
        white-space: nowrap;
      }
    }
  }
}


.whatsapp {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 77px;
  right: 24px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  line-height: 1.4;
}
