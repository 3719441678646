@keyframes isCompany {
  from {
    background-color: orange;
  }
  to {
    background-color: transparent;
  }
}

@keyframes slideDown {

  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 200px;
    opacity: 1;
  }
}

@keyframes growUp {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  40% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;

  }
}

@keyframes slideIn {
  0% {
    opacity: 1;
    left: 70%;
  }
  10% {
    left: -5%;
  }
  100% {
    left: 0;
    opacity: 1;

  }
}

$movement: 5px;
@keyframes shake {
  0% {
    transform: translateY(-$movement)
  }
  25% {
    transform: translateY($movement)
  }
  40% {
    transform: translateY(-$movement)
  }
  65% {
    transform: translateY($movement)
  }
  80% {
    transform: translateY(-$movement)
  }
  950% {
    transform: translateY($movement)
  }
}
